body{
    overflow-x: hidden;
}
.App{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    }
  

    .dogimage{
        background-image: url("../images/Group\ 22.png");
        
    }
    .dogimage img{
        z-index:99999;
    }
    table {
    border: 0.5px solid rgb(37, 37, 37);
    width: 1200px;
    height: 350px;
    
    }
    .table-spacing {
        border-collapse: separate;
        border-spacing: 0 3px;
    }
    
    td {
    text-align: center;
    border-collapse: separate;
    border-spacing: 105px;
    }
    tr:nth-child(odd){
        background-color: #f2f2f2;
        text-align: center;
  
    }

    tr:nth-child(even){
        background-color: #fafafa;
    }

    .button-position{
        background-color: #0000a0; 
        /* border: none; */
        color: white;
        padding: 15px 32px;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
       border-radius: 300px;
      }
      .content p{
       
       text-align: start;
       font-family: mars_centrabook;
       font-size: 16px;
      }
      .button-align {
        display: flex;
        justify-content: center;
      }
      .h5-style h5{ 
        text-align: center;
        font-size: 24px;
        color: #4D4D4D;
        font-family: mars_centrabook;
      }
      .table-spacing tr th{
        font-size: 18px;
        font-family: mars_centrabook;
        color: #4D4D4D;
      }
      .table-spacing tr td{
        font-size: 16px;
        font-family: mars_centrabook;
        color: #4D4D4D;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px){
        .h5-style h5{ 
            font-size: 20px;
        }
       
      }
      @media screen and (min-width: 1025px){
      .feeding-top{
        margin-top: 20%;
    }
}
@media screen and (max-width: 767px){
    .h5-style h5{ 
        font-size: 16px;
    }
   
.table-spacing tr th {
    font-size: 12px;
}
.content p{
    font-size: 12px;
}
.table-spacing tr td {
    font-size: 10px;
}
}
  
  
#root {
    overflow: hidden;
}

.form-group {
    display: flex;
    justify-content: space-between;
}

.breed-form {
    border-radius: 50px !important;
    background-color: #e1e1e1 !important;
    color: #070707 !important;
    width: 50% !important;
    font-size: 18px;
    height: 41px !important;
    font-family: mars_centrabook;
}

.form-tracker label {
    line-height: 2.5;
    font-size: 19px;
    font-family: mars_centrabook;
}

.form-tracker label sup {
    color: red;
}

.form-control:focus {
    box-shadow: none;
    background-color: #e1e1e1;
}

.ExampleInput {
    width: 24% !important;
    border-radius: 50px !important;
    background-color: #e1e1e1 !important;
    color: #0c0c0c !important;
    font-size: 18px;
    height: 41px !important;
    font-family: mars_centrabook;
}

#formGroupExampleInput {
    margin-left: 28%;
}

.form-tracker .feed-button button:nth-child(1) {
    background-color: #E9397B;
    border-color: #E9397B;

}

.form-tracker .feed-button button:nth-child(2) {
    background-color: #005BAA;
    border-color: #005BAA;

}

.form-tracker .feed-button button {
    color: #fff;

    border-radius: 50px;
    width: 160px;
    margin-left: 29px;
    font-size: 16px;
    font-family: mars_centrabold;
    font-weight: 900;
    padding-top: 10px;
}

.store-buy h1 {
    color: #005BAA;
    font-size: 60px;
    font-weight: 600;
    font-family: mars_centrabold;
}

.store-buy p {
    color: black;
    font-size: 36px;
    font-family: mars_centrabook;
}

.answer_list {
    display: none;
}

.answer-list-text h1 {
    color: #005BAA;
    font-size: 58px;
    font-weight: 900;
    text-align: center;
    font-family: mars_centrabold;
    padding: 0 20px;
}


.form-tracker .feed-button {
    display: flex;
    justify-content: space-between;
}

.feed-border {
    border: 1px solid #989da1;
    padding: 15px;
    margin-left: 200px;
    max-width: 733px;
    margin-right: -43px;
    /* height: 427px; */
}

.feedtracker {
    border: 1px solid #989da1;
}

.breed-form-tracker {
    background-color: #e1e1e1 !important;
    color: #989da1 !important;
    padding-bottom: 40px !important;
    /* padding-left: 45px !important; */
    padding-top: 20px !important;
    text-align: center;

}

.ghmc-logo  {
    width: 25%;
    margin-top: 16px;
    margin-bottom: 150px;
}

.image-overlap {
    position: absolute;
    right: 0;
}

.vector-img img {
    position: absolute;
    top: 430px;
    width: 9%;
}

.img-width {
    width: 750px;
    height: 600px;
}

.yellowleft img {
    position: relative;
    left: -50%;
}

.f-logo-bg {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    right: 37%;
    top: -16%;
}

.flower-logo {
    margin-left: 40%;
}

.text-content {
    margin-top: -6%;
}

.text-content h2 {
    color: #005BAA;
    font-size: 40px;
    font-weight: 600;
    border-bottom: 9px solid #e15b15;
    width: fit-content;
    line-height: 0.9;
    font-family: mars_centrabook;
}

.text-content h1 {
    color: #005BAA;
    font-size: 40px;
    font-weight: 700;
    font-family: mars_centrabold;
    font-size: 69px;

}

.text-content p {
    font-size: 16px;
    color: #4D4D4D;
    max-width: 446px;
    line-height: 2rem;
    font-family: mars_centrabook;
}

.text-border {
    border: 5px solid #ffc728;
    margin-top: -17px;
    width: 330px;
    margin-right: 216px;
}

.store-dogimg {
    width: 70%;
}

.location-icon {
    width: 23px;
    margin-left: -31px;
    margin-right: 5px;
}

.connector {
    position: relative;
    left: 87px;
    top: 20px;
}

.hero-dog {
    margin-left: 280px;
    margin-top: -160px;
}

.dogflowerlogo {
    position: relative;
    top: -50px;
    left: 73px;
    width: 15%;
}

.blurvector-img img {
    position: absolute;
    width: 12%;
}

.blur-img {
    right: 0;
}

.blur-img-left {
    left: 0;
}

.store-buy .feed-button button {
    color: #fff;
    background-color: #005BAA;
    border-color: #005BAA;
    border-radius: 50px;
    width: 180px;
    font-size: 16px;
    font-family: mars_centrabook;
    box-shadow: 0px 4px 40px #0000A069;
    padding-top: 10px;
}


@media screen and (min-width: 1025px) {
    .feedtracker {
        max-width: 300px;
    }
    .form-tracker {
        margin-top: 25%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .blurvector-img img {
        width: 12%;
        height: 35%;
    }

    .store-buy h1 {
        font-size: 35px;
    }

    .store-buy p {
        font-size: 20px;
    }

    .form-tracker {
        margin-top: 5%;
    }

    .form-tracker .feed-button button {
        font-size: 12px;
    }

    .form-bg-right {
        left: 660px;
        top: 630px;
    }

    .yellowleg-bg {
        left: 25px;
    }

    .text-content h2 {
        font-size: 20px;
        border-bottom: 5px solid #e15b15;
    }

    .text-content h1 {
        font-size: 35px;
    }

    .text-border {
        width: 240px;
    }

    .text-content p {
        font-size: 14px;
        max-width: 360px;
    }

    .vector-img img {

        top: 255px;
        width: 42px;
        height: 127px;
    }

    .feed-border {

        margin-left: 120px;
    }

    .feedtracker {
        max-width: 222px;
    }

    .form-tracker label {
        font-size: 14px;
    }

    .breed-form {
        font-size: 14px;
        height: 32px !important;
    }

    .ExampleInput {
        font-size: 14px;
        height: 32px !important;
    }

    #formGroupExampleInput {
        margin-left: 25%;
    }

    .breed-form-tracker {
        font-size: 12px;
        text-align: center;
        padding-left: 16px !important;

    }

    .form-bg-right {
        width: 100px;
    }

    .store-dogimg {
        margin-left: 13%;
    }

    .store-buy {
        margin-left: 5%;

    }

    .form-tracker .feed-button button {
        width: 100px;
        margin-left: 23px;
    }

    .image-overlap img {
        width: 100%;

    }
}

@media screen and (max-width: 767px) {
    .form-bg-right {
        left: 358px;
        width: 189px
    }

    .form-bg {

        width: 548px;
    }

    .yellowleg-bg {
        position: relative;
        top: 0px;
        left: 0px;
        width: 400px;
    }

    .vector-img img {
        position: absolute;
        top: 277px;
        width: 17px;
        height: 100px;
    }

    .feed-border {
        margin-left: 0;
    }

    .breed-form {
        height: 32px !important;
        font-size: 12px;
    }

    .form-tracker label {
        font-size: 10px;
    }

    #formGroupExampleInput {
        margin-left: 18%;
    }

    .ExampleInput {
        width: 29% !important;
        height: 32px !important;
        font-size: 12px;
    }

    .breed-form {
        width: 60% !important;
    }

    .store-dogimg {
        margin-left: 45px;
    }

    .hero-dog {
        margin-left: 178px;
        margin-top: -65px;
        width: 52%;
    }

    .connector {
        left: 37px;
    }

    .image-overlap img {
        width: 100%;
    }

    .text-content h2 {
        font-size: 15px;
        border-bottom: 5px solid #e15b15;
    }

    .text-content h1 {
        font-size: 30px;
    }

    .text-content p {
        font-size: 14px;

    }

    .form-tracker .feed-button button {
        width: 27%;
        font-size: 14px;
        margin-left: 20px;
    }

    .store-buy h1 {
        font-size: 30px;
    }

    .store-buy p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .puppy-img img {
        margin-left: -20px;
    }

    .image-overlap {
        width: 50% !important;
    }

}

@media screen and (min-width: 390px) and (max-width: 844px) {
    .image-overlap {
        width: 55%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .image-overlap {
        width: 45%;
    }
}
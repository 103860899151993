.store-text h3 {
  font-size: 57px;
  font-family: mars_centrabold;
  color: #005baa;
  font-weight: 900;
}
.store-input input {
  width: 100%;
  border: 1px solid #f1efef;
  background-color: #f1efef;
  border-radius: 30px;
  font-size: 20px;
  padding: 8px 13px;
  font-family: mars_centrabook;
}
.store-radio label {
  font-family: mars_centrabook;
  font-size: 20px;
  color: #676767;
}
input[name="inlineRadioOptions"] {
  accent-color: #676767;
}
.maps-data {
  display: flex;
  justify-content: space-between;
  background-color: #eeecec;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.googlemaps {
  width: 155% !important;
}
.maps-data-top {
  overflow-y: scroll;
  height: 450px;
}
.store{
    margin-top: 12%;
}
.arrow{
  margin-left: 37%
}
.m6 {
  margin-top: 12%;
}
.abc {
  min-width: 200px;
}
@media screen and (max-width: 767px) {
  .maps-data-top {
    margin-top: 140px !important;
  }
}
@media screen and (max-width: 767px){
  .maps-data-top {  
  margin-top:40rem!important;  
  }  
  .googlemaps {  
  height:900px!important;  
  }  
  }
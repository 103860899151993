.footer-bg{
  background-image: linear-gradient(#0088FF, #005BAA,#0B4171);
}
.footer-address p{
  color: white;
  font-size: 17px;
  font-family: mars_centrabook;
  margin-top: 25%;
}
.copyright p{
  color: white;
}
.footer-width img{
  width:120px;
  margin-top: 50%;
}
.footer-line img{
  margin-top: 4rem;
  margin-left: -2rem;
}
.copyright p{
  margin-top:2.25rem;
  font-size: 16px;
  font-family: mars_centrabook;
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
.footerdivider-1{
  position: absolute;
  left:480px;
}
.footer-address p {
  font-size: 12px;
}

.footerdivider-2{
  position: absolute;
  
}
.footer-line img {
  margin-top: 4rem;
  margin-left: 2rem;
  width: 300px;
}
.footer-width img {
width: 108px;
margin-top: 28px;
}
.copyright p{
  font-size: 12px;
}
}
@media only screen and (max-width: 767px){
  .footer-line img {
    display: none;
  }
  .footer-address p {
    font-size: 12px;
    margin-top: 0;
  }
  .copyright p{
    font-size: 12px;
  }
  .footer-width img{
    margin-top: 0;
  }
}
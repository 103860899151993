.heading{
    text-decoration: underline;
    font-family: "mars_centrabook";
    font-weight: 700;
    color: #005BAA;
    font-size: 2.1rem;
}
.terms li{
    line-height: 2rem;
    font-family: "mars_centrabook";
    
}
.red-cl{
    color: red;
}
.side-heading{
  font-family: "mars_centrabook";
  font-weight: 900;
}
@font-face {
    font-family: 'mars_centrabold';
    src: url('../../fonts/marscentra-book-webfont.woff2') format('woff2'),
        url('../../fonts/marscentra-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mars_centrabook';
    src: url('../../fonts/marscentra-book-webfont.woff2') format('woff2'),
        url('../../fonts/marscentra-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mars_centraextrabold';
    src: url('../../fonts/marscentra-extrabold-webfont.woff2') format('woff2'),
        url('../../fonts/marscentra-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.section-top{
    margin-top: 10%;
  }
  @media only screen and (min-width: 768px){
  .puppy-img img{
      width: 436px;
      height: 377px;
  }
  }
  .puppy-text h3{
    font-size: 69px;
    color: #005BAA;
    font-weight: 500;
    padding: 0 20px;
    font-family: mars_centrabold;
  }
  
  .puppy-text p{
      font-size: 16px;
      color: #4D4D4D;
      padding: 0 20px;
      font-family: mars_centrabook;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
      .puppy-img img{
          margin-left: 75px;
      }
      .puppy-text h3 {
          font-size: 35px;
      }
      .puppy-text p {
          font-size: 14px;
      }
     
  
  }
.dogs-size img{
    width:95%;
    margin-top:100px;
}
.rightclick img{
    margin-top: 10px;
}
.rightclick h2{
    color: #005BAA;
    font-size: 69px;
    font-weight: 900;
    font-family: mars_centrabold;
}
.icons-content img{
width: 175px;
    height: 152px
}
.icons-content h2{
    color:#5D5D5D;
    text-align: center;
    font-size: 30px;
    font-family: mars_centrabold;
    font-weight: 600;
}
.content-style h3{
    color:#005BAA;
    font-size: 69px;
    text-align: center;
    font-family: mars_centrabold;
    font-weight: 900;
}
.content-style p{
   
    text-align: center;
    font-size: 16px;
    font-family: mars_centrabook;
    padding: 0 120px;
}
.text-center{
    text-align: center;
}
.rightclick p{
    font-size: 16px;
    font-family: mars_centrabook;
}
/* @media only screen and (max-width: 1200px) and (min-width: 1025px){ */

    @media only screen and (min-width: 1025px) {
        .dogs-size img{
    
            margin-top:100px;
        }
       
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px){
        .icons-content img{
            text-align: center;
        }
        .content-style h3 {
         font-size: 35px;
        }
        .icons-content h2 {
            margin-left: 32px;
            font-size: 18px;
        }
        .rightclick h2 {
            font-size: 34px;
        }

    }
    @media only screen and (max-width: 767px) {
        .content-style h3 {
            font-size: 30px;
           }
           .content-style p {
            padding: 0;
            font-size: 12px;
           }
           .rightclick h2 {
            font-size: 30px;
           }
           .icons-content h2 {
            font-size: 16px;
           }
    }
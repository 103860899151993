.cards-top{
    margin-top: 13%;
    }
    .training-cards h4{
        font-size: 24px;
        font-family: mars_centrabook;
    }
    .training-cards{
        text-align: center;
    }
    .training-cards img{
        width: 170px;
        height: 166px;
    }
    .training-cards p{
        font-size: 16px;
        padding: 0 35px ;
        color: black;
        font-family: mars_centrabook;
    }
    .training-button .readmore-btn:hover {
        background-color: #005BAA;
        color: white;
    }
    .training-button .readmore-btn{
    border-radius: 50px;
    background-color: #ffffff;
    color: #005BAA;
    padding: 7px 27px;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid  #005BAA;
    font-family: mars_centrabook;
    padding-top: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px){
        .training-cards h4 {
            font-size: 20px;
        }
        .training-cards p {
            font-size: 14px;
        }
    }
    @media screen and (min-width: 768px){
        .training-cards-bg{
            background-image: url("../images/zigzag.png");
            background-repeat: no-repeat;
        }
    }
    
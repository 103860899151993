.qr-main-form input[type=text] {
  width: 100%;
  padding: 6px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: mars_centrabook;
}
.qr-blur{
  margin-top: 30% !important;
}
#petparentname,#paretname{
  background-image: url(../images/icons8-lock.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-color: #BCBCBC;
}
.qr-main-form input[type=email] {
  width: 100%;
  padding: 6px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-family: mars_centrabook;

}
.qr-main-form textarea{
  box-sizing: border-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BCBCBC;
  opacity: 1;
  font-size: 20px;
  font-family: mars_centrabook;
}
.qr-label{
  color: #161718;
  font-size: 24px;
  font-family: mars_centrabook;
  margin-top: 14px;
}
.qr-type{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BCBCBC;
  opacity: 1;
}
.submit-btn{
  border-radius: 25px;
  background-color: #0000A0;
  color: white;
  text-transform: uppercase;
  padding: 8px 53px;
  font-size: 18px;
  font-family: mars_centrabold;
  padding-top: 13px;
}
.reset-btn{
  border-radius: 25px;
  background-color:#CB5F27;
  color: white;
  text-transform: uppercase;
  padding: 0px 53px;
  font-size: 18px;
  font-family: mars_centrabold;
  padding-top: 6px;
}
.qr-button{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.vector-img-qr{
  position: relative;
  top: 10px;
  right: 296px;
}
.download-apps h3{
  font-size: 24px;
  color: #0000A0;
  font-family: mars_centrabold;
  font-weight: 900;
  margin-top: 13px
}



.download-apps{
 margin-top: 70px;
}
.textarea-form textarea{
  width: 100%;
  height: 180px;
}
@media screen and (min-width: 1024px){
.qr-form{
margin-top: 12%;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px){
  .qr-image img{
      max-width: 120%;
  }
  .qr-label {
    color: #161718;
    font-size: 20px;
  }
  .qr-main-form input[type=text] {
    font-size: 14px;
  }
  .qr-main-form input[type=email] {
    font-size: 14px;
  }
  .qr-main-form textarea{
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .download-apps{
      display: flex;
  }

}
@media screen and (max-width: 767px){
  .qr-image img{
  width: 129%;
  position: relative;
  top: -175px;
  right: 90px
  }
  .qr-label {
    color: #161718;
    font-size: 16px;
  }
  .qr-main-form input[type=text] {
    font-size: 14px;
  }
  .qr-main-form input[type=email] {
    font-size: 14px;
  }
  .qr-main-form textarea{
    font-size: 14px;
  }
  .download-apps .apps{
      display: flex;
      text-align: center;
      margin-right: 62px;
  }
  .download-apps .apps a{
    margin-left: -34px;
    margin-right: 47px;
  }
}
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* background-color: #333; */
    background: rgba(0,123,255,.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top:hover {
    opacity: 0.8;
  }
  
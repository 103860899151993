.community-text h3 {
    font-size: 69px;
    font-weight: 900;
    text-align: center;
    color: #005BAA;
    font-family: mars_centrabold;
}

.community-text p {
    color: #4D4D4D;
    font-size: 16px;
    text-align: center;
    padding: 0 19%;
    font-family: mars_centrabook;
}

.community-cards img {
    width: 290px;
    height: 283px;
}

.community-cards h4 {
    font-size: 30px;
    color: #005BAA;
    font-weight: 700;
    font-family: mars_centrabold;
}

.community-cards p {
    font-size: 16px;
    font-family: mars_centrabook;
}

.community-cards {
    text-align: center;
    padding: 0 12px;
}

.loren-image img {
    width: 75%;
}

.loren-text h3 {
    font-size: 69px;
    color: #005BAA;
    font-weight: 600;
    font-family: mars_centrabold;
}

.loren-text p {
    font-size: 16px;
    font-family: mars_centrabook;
}

.lorem-form {
    display: block;
}

.lorem-form p {
    font-size: 16px;
    width: 88%;
}

.lorem-form {
    width: 88%;

}

.lorem-form input {
    border-radius: 50px;
    padding: 12px 25px;
    border: 1px solid #005BAA;
    font-size: 20px;
    font-family: mars_centrabook;
}

.lorem-form button {
    border-radius: 50px;
    background-color: #005BAA;
    color: white;
    font-size: 24px;
    padding: 7px 46px;
    font-family: mars_centrabold;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .community-cards img {
        width: 200px;
        height: 230px;
    }

    .community-cards h4 {
        font-size: 14px;
    }

    .community-cards p {
        font-size: 12px;
    }

    .community-text p {
        padding: 0;
    }

    .loren-text h3 {
        font-size: 35px
    }

    .loren-text p {
        font-size: 14px
    }

    .loren-image {
        margin-top: 70px;
    }

    .community-text h3 {
        font-size: 35px;
    }
}

@media screen and (max-width: 767px) {
    .community-text h3 {
        font-size: 30px;
    }

    .community-text p {
        padding: 0;
    }

    .community-cards img {
        margin-top: 40px;
    }

    .loren-text h3 {
        font-size: 30px;
    }

    .loren-image img {
        margin-left: 35px;
    }

    .community-cards h4 {
        font-size: 20px;
    }
}

@media screen and (min-width: 1025px) {
    .community-text {
        margin-top: 20%;
    }
}
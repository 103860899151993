.grooming-top{
  margin-top: 25%;
}
.gromming-text h2{
   text-align: center;
   font-size: 57px;
   padding:  0  14%;
   color: #005BAA;
   font-weight: 600;
   font-family: mars_centrabold;
}

.gromming-text p{
    text-align: center;
    font-size: 16px;
    padding:  0  18%;
    color: #4D4D4D;
    font-family: mars_centrabook;
 }
 .grooming-cards img{
   width: 100%;
 }
 .grooming-cards {
    padding: 40px;
 }
 .grooming-cards h4{
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    color: #005BAA;
    font-family: mars_centrabold;
    margin-top: 10px;

 }
 .grooming-cards p{
    font-size: 16px;
    text-align: center;
    color: #4D4D4D;
    font-family: mars_centrabook;
 }
 .grooming-button{
   text-align: center;
 }
.grooming-button .readmore-btn{
    border-radius: 50px;
    background-color: #ffffff;
    color:   #005BAA;
    text-transform: uppercase;
    padding: 0px 25px;
    font-size: 24px;
    font-weight: 600;
    border: 2px solid  #005BAA;
    font-family: mars_centrabook;
    padding-top: 6px;
  }
  .grooming-button .readmore-btn:hover{
   background-color: #005BAA;
   color: white;
  }

  .grooming-vets-bg{
   background-color: #E2E2E2;
   margin-bottom: -40px;
  }
  .vets-text h3{
   color: black;
   font-size: 57px;
   font-weight: 600;
   font-family: mars_centrabold;
  }
  .grooming-vets-bg .readmore-btn{
   border-radius: 50px;
   background-color: #ffffff;
   color: #005BAA;
  
   padding: 7px 27px;
   font-size: 21px;
   font-weight: 600;
   border: 2px solid   #E2E2E2;
   font-family: mars_centrabold;
 }
 .vets-text{
   padding: 47px;
 }
 .vets-img img{
   position: relative;
    top: -37px;
    width: 70%;
}
@media screen and (min-width: 768px) and (max-width: 1024px){
   .vets-text{
      padding: 0px;
    }
    .vets-text h3{
      font-size: 30px;
      padding: 10px 20px;
    }
    .grooming-button {
      margin-left: -10px;
  }
  .grooming-vets-bg .readmore-btn{
   margin-left: 14px;
  }

}
@media screen and (max-width: 767px){
   .vets-text h3{
      font-size: 30px;
}
.vets-text{
   padding: 58px;
 }
 .vets-img img {
   margin-left: 60px;
 }
}